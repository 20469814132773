import React from 'react'
import { classes } from './__index'
import GeneratorFormFromExcel from './components/generateFormFromExcel'

const POC = () => {
	return (
		<>
			<div className={classes.root}>
				<h1>Preuve de Concept</h1>
				<GeneratorFormFromExcel excelFileUrl="/data/form1.xlsx" />
			</div>
		</>
	)
}

export default POC
